import React from 'react';
import { Box, VStack, useBreakpointValue, Image } from '@chakra-ui/react';
import NavBar from '../../components/general/navbar';
import FooterPanel from '../../components/panels/footerpanel';
import RegularText from '../../components/text/regulartext';
import SubtitleText from '../../components/text/subtitletext';
import BlogImage from '../../assets/blog/NickAccomandoBlog.png';
import SmallSubtitleText from '../../components/text/smallsubtitletext';
import NickAccomando from '../../assets/blog/NickAccomando.png';
import SmallerSubtitleText from '../../components/text/smallersubtitletext';

const LandingPage = () => {
    const paddingX = useBreakpointValue({ base: 8, md: 60 }); // Adjusted paddingX to match other pages

    return (
        <Box bg="#101012" w="100%" h="auto">
            <Box position="fixed" top="0" left="0" width="100%" zIndex="1000">
                <NavBar />
            </Box>
            <VStack spacing={0} w="100%">
                <Box
                    position="relative"
                    textAlign="left"
                    color="white"
                    h="auto"
                    w="100%"
                    pt="20vh"
                    pr={paddingX}
                    pl={paddingX}
                    pb="5vh"
                >
                    <RegularText 
                        sx={{ mb: '2vh', mt: '1vh', cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                        onClick={() => window.location.href = '/blog'}
                    >
                        <span style={{ marginRight: '8px' }}>←</span>
                        Back to all blogs
                    </RegularText>
                    <Image src={BlogImage} alt="Blog" w="100%" borderRadius="xl" mb={10} />
                    <SubtitleText sx={{ lineHeight: '1'}}>Expert interview: HIPAA compliance for SaaS companies</SubtitleText>
                    <RegularText sx={{ color: '#dddddd', mt: '2vh' }}>March 21st, 2024</RegularText>

                    <RegularText sx={{ mb: '4vh', mt:'4vh' }}>
                    The United States takes a very sectoral approach to data privacy regulations, which can often lead to confusion on what is expected for a SaaS company when they are handling personal information. When it comes to personal health information, the Health Insurance Portability and Accountability Act (“HIPAA”) has set the standard for data privacy going back more than 25 years.
</RegularText>

<RegularText sx={{ mb: '4vh', mt:'4vh' }}>
We sat down with HIPAA expert, Nick Accomando, to get his perspective on navigating some of the core aspects of HIPAA. 
</RegularText>

                    <SmallSubtitleText>Tell us about your background as a HIPAA expert.</SmallSubtitleText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                    I have been working as a lawyer in the healthcare space for about 17 years now and have been focused on data privacy issues for the vast majority of it. Prior to my current role as the Chief Compliance & Privacy Officer at Flatiron Health, I held global roles at GSK, IQVIA, and IBM Watson Health, where my work was focused at the intersection of data, technology, AI, and privacy in the healthcare space. My expertise is focused in digital innovation, de-identification methodologies, and transformative technologies in Big Data. 
                    </RegularText>

                  <SmallSubtitleText>
                  What is HIPAA in its simplest sense?

                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  I find the US approach to data privacy so interesting because pretty much the rest of the world takes one approach to data privacy and the US takes a very different approach. Pretty much everywhere else in the world has an overarching privacy law that breaks down personal information into different categories (e.g., basic, sensitive, pseudonymous) and then lays out the privacy and security requirements based on the type of personal information you are handling, regardless of where it came from.
                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  Here in the US, HIPAA actually regulates entities first and then sets expectations for how they handle certain types of information. At its core, HIPAA regulates covered entities and business associates, and sets the privacy and security expectations for how those entities handle protected health information (“PHI”). So, when you are trying to determine if HIPAA applies to your handling of personal information, you should always start with two questions: 1) Am I acting as a covered entity or business associate; and 2) am I processing protected health information? If the answer to both those questions is yes, then you are operating within the scope of HIPAA.
                  </RegularText>


                  <SmallSubtitleText>
                  How can I determine if I’m a Covered Entity or a Business Associate under HIPAA?
                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  While there is some nuance around the transmission of electronic patient health information and billing, covered entities generally fall into 3 buckets under HIPAA: 1) health plans (e.g., health insurance companies), 2) healthcare clearinghouses (e.g., transmitters of medical claims data); and 3) health care providers. I will say that healthcare providers are broadly defined and include hospitals, doctor’s offices, nursing homes, pharmacies, and laboratories.  
                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  A business associate is a person or entity that performs certain functions or activities that involve the use or disclosure of protected health information on behalf of, or provides services to, a covered entity. As a general rule of thumb, if a covered entity is contracting you to perform a service and that service means you are accessing protected health information belonging to the covered entity, then there is a very strong chance you are acting as a business associate. 


                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  You can find in-depth information about covered entities and business associates in <a href="/blog/who-does-hipaa-apply-to" style={{ textDecoration: 'underline', color: '#dddddd' }}>this useful blog post</a>. The US Dept. of Health and Human Services (“HHS”) and the US Office of Civil Rights (“OCR”) also provide free resources for navigating these questions. I would say I refer to the <a href="https://www.hhs.gov/hipaa/for-professionals/faq/index.html" style={{ textDecoration: 'underline', color: '#dddddd' }}>HHS’s HIPAA FAQs for professionals</a> almost daily. 


                  </RegularText>


                  <SmallSubtitleText>
                  Are you saying not all sensitive personal health information in the US is protected by HIPAA?

                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  I am – in fact, I’d say that there are a lot of everyday situations where HIPAA does not apply to the processing of sensitive personal health information. For instance, business-to-consumer health and wellness apps that directly collect a consumer's personal health information are largely outside the scope of HIPAA. When you download a health and wellness app on your phone for personal use and enter your health information into it, there is a really good chance that information is not protected under the requirements of HIPAA.  


                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  Another example where the processing of sensitive personal health information is largely outside the scope of HIPAA is clinical research. Regulated clinical trials certainly have their own set of applicable regulations to consider around privacy and data integrity, but they largely fall outside the scope of HIPAA. 


                  </RegularText>



                  <SmallSubtitleText>
                  What do you see as the biggest consequences for not being HIPAA compliant?

                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  I want to start by saying that when HIPAA applies to you, complying is not optional, it is a requirement and failure to comply can lead to corrective action, including financial penalties. Since the compliance date of the Privacy Rule in April 2003, OCR has received over 351,372 HIPAA complaints and has initiated over 1,183 compliance reviews. To date, OCR settled or imposed a civil money penalty in 142 cases resulting in a total dollar amount of $142,448,772.00.


                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  In addition, there is very real reputational harm that comes with failure to meet HIPAA requirements when providing tools and services in the healthcare spaces. Covered entities like health insurance companies and hospital systems take HIPAA very seriously and will not be willing to work with companies that don’t take HIPAA just as seriously. 


                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  To note: HIPAA is enforced by the Oﬃce for Civil Rights (OCR) of the US Department of Health and Human Services (HHS). The Federal Trade Commission (FTC) also enforces good security practices and can prosecute companies that don’t have them in place.


                  </RegularText>




                  <SmallSubtitleText>
                  Can you outline what getting HIPAA compliant looks like for SaaS companies?

                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  Once a SaaS company decides it’s in scope for HIPAA compliance, they should generally start with a risk assessment, which is required under HIPAA. Once the risk assessment is complete, implementation is where I see smaller companies get tripped up the most because there is a ton of information to process, decisions to be made, and infrastructure to build out. 


                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  When it comes to SaaS, I’d say the biggest challenge is ensuring adequate technical controls within the cloud infrastructure. The large cloud providers like AWS, GCP, and Azure offer HIPAA-eligible cloud infrastructure, but it is largely on the user to configure it appropriately before they can call themselves HIPAA-compliant. Figuring out this configuration can be really challenging and takes weeks to months of work.


                  </RegularText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  The other area I would call out is the administrative controls, which include all the requisite privacy and security policies that must be implemented and followed. Building out these policies can be incredibly time-consuming and requires subject matter expertise to address the content. 


                  </RegularText>





                  <SmallSubtitleText>
                  Can Delve help companies with HIPAA compliance?

                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  The Delve platform has really impressed me. I think it offers very real solutions to the challenges associated with building out technical infrastructure and policy development. It also couples those solutions with real-time security insights and monitoring.


                  </RegularText>






                  <SmallSubtitleText>
                  Any final thoughts?
                  </SmallSubtitleText>
                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                  I think it is clear that HIPAA compliance can be really challenging and we have only hit on a few key areas today. When I think about HIPAA compliance, it is all about arming yourself with the right tool box to proactively address HIPAA requirements. Delve certainly seems like a tool I’d want in my tool box.

                  </RegularText>





                  <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                    <strong style={{color: '#DDDDDD'}}>Disclaimer</strong><br/>
                  This interview was conducted with Nick Accomando in his independent capacity as a HIPAA expert and he is not a paid employee or consultant of Delve. The opinions are his own and the interview does not reflect the views, strategies, or opinions of his employer, Flatiron Health. In addition, this interview is provided at your convenience and should not be taken as legal advice.

                  </RegularText>
<Box
    w="100%"
    borderWidth='1px'
    borderColor='rgba(255, 255, 255, 0.08)'
    h="auto"
    bg="rgba(255, 255, 255, 0.04)"
    mt="4vh"
    p="5"
    borderRadius="md"
    display="flex"
    flexDirection={{ base: "column", md: "row" }}
    alignItems="center"
>
    <Image
        src={NickAccomando}
        alt="Nick Accomando"
        borderRadius="full"
        boxSize={{ base: "150px", md: "200px" }}
        mr={{ base: "0", md: "4" }}
        mb={{ base: "4", md: "0" }}
    />
    <VStack align='left'>
        <SmallSubtitleText>Nick Accomando
</SmallSubtitleText>
        <RegularText sx={{ color: '#DDDDDD' }}>Chief Compliance & Privacy Officer, Flatiron Health
</RegularText>
        <RegularText>
        Nick Accomando is the Chief Compliance & Privacy Officer at Flatiron Health. He has 17 years of experience as a lawyer in the healthcare space. Previously, he held global roles at GSK, IQVIA, and IBM Watson Health, where his work was focused on the intersection of data, technology, AI, privacy, and healthcare.
</RegularText>
    </VStack>
</Box>
</Box>
</VStack>
<Box mt='4rem' mb='3rem' height="1px" bgGradient="linear(to-l, transparent, #dddddd, transparent)" width="100%" />
<FooterPanel />
</Box>
);
};

export default LandingPage;