import React from 'react';
import { Box, VStack, Image, useBreakpointValue } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';
import Feature1 from '../../assets/feature1.png';
import Feature2 from '../../assets/feature2.png';
import Feature3 from '../../assets/feature3.png';

const TitlePanel = () => {
  const marginX = useBreakpointValue({ base: 4, md: 40 });
  const paddingX = useBreakpointValue({ base: 4, md: 4 });
  const paddingBottom = useBreakpointValue({ base: 8, md: 16 });
  const spacing = useBreakpointValue({ base: 4, md: 70 });

  return (
    <Box ml={marginX} mr={marginX} mt={5} spacing={spacing} pr={paddingX} pl={paddingX} pb={paddingBottom} align='left' color="white">
        <SubtitleText>
            Unparalleled features
        </SubtitleText>

        <Box display="grid" gridTemplateColumns={{ base: "1fr", sm: "repeat(2, 1fr)", lg: "repeat(3, 1fr)" }} gap={4} mt={6}>
            <Box
                position="relative"
                borderRadius="lg"
                overflow="hidden"
                mb={4}
            >
                <Image src={Feature1} alt="Feature 1" objectFit="cover" width="100%" height="100%" />
                <VStack
                    align="left"
                    sx={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      p: 2,
                  }}>
                    <RegularText sx={{color: "#dddddd"}}>
                      Built for startups
                    </RegularText>
                    <RegularText>
                      Zero fluff. Designed for agile teams, not 50-person IT departments.
                    </RegularText>
                </VStack>
            </Box>
            <Box
                position="relative"
                borderRadius="lg"
                overflow="hidden"
                mb={4}
            >
                <Image src={Feature2} alt="Feature 2" objectFit="cover" width="100%" height="100%" />
                <VStack
                    align="left"
                    sx={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      p: 2,
                  }}>
                    <RegularText sx={{color: "#dddddd"}}>
                      1-Click fixes
                    </RegularText>
                    <RegularText>
                      From instant GitHub PRs to autofilled security questionnaires, AI is there to help.
                    </RegularText>
                </VStack>
            </Box>
            <Box
                position="relative"
                borderRadius="lg"
                overflow="hidden"
                mb={4}
            >
                <Image src={Feature3} alt="Feature 3" objectFit="cover" width="100%" height="100%" />
                <VStack
                    align="left"
                    sx={{
                      position: "absolute",
                      top: "10px",
                      left: "10px",
                      p: 2,
                  }}>
                    <RegularText sx={{color: "#dddddd"}}>
                      Continual security
                    </RegularText>
                    <RegularText>
                      After you get compliant, have peace of mind with continual security monitoring.
                    </RegularText>
                </VStack>
            </Box>
        </Box>
    </Box>
  );
};

export default TitlePanel;