import React from 'react';
import { Box, VStack, useBreakpointValue, Image } from '@chakra-ui/react';
import NavBar from '../../components/general/navbar';
import FooterPanel from '../../components/panels/footerpanel';
import RegularText from '../../components/text/regulartext';
import SubtitleText from '../../components/text/subtitletext';
import BlogImage from '../../assets/blog/JesusJimenezBlog.png';
import SmallSubtitleText from '../../components/text/smallsubtitletext';
import JesusJimenez from '../../assets/blog/JesusJimenez.png';
import SmallerSubtitleText from '../../components/text/smallersubtitletext';

const LandingPage = () => {
    const paddingX = useBreakpointValue({ base: 8, md: 60 }); // Adjusted paddingX to match other pages

    return (
        <Box bg="#101012" w="100%" h="auto">
            <Box position="fixed" top="0" left="0" width="100%" zIndex="1000">
                <NavBar />
            </Box>
            <VStack spacing={0} w="100%">
                <Box
                    position="relative"
                    textAlign="left"
                    color="white"
                    h="auto"
                    w="100%"
                    pt="20vh"
                    pr={paddingX}
                    pl={paddingX}
                    pb="5vh"
                >
                    <RegularText 
                        sx={{ mb: '2vh', mt: '1vh', cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                        onClick={() => window.location.href = '/blog'}
                    >
                        <span style={{ marginRight: '8px' }}>←</span>
                        Back to all blogs
                    </RegularText>
                    <Image src={BlogImage} alt="Blog" w="100%" borderRadius="xl" mb={10} />
                    <SubtitleText>Who Does HIPAA Apply To?</SubtitleText>

                    <RegularText sx={{ color: '#dddddd' }}>February 20th, 2024</RegularText>

                    <RegularText sx={{ mb: '4vh', mt:'4vh' }}>
                        If you handle protected health information in any capacity, you need to be aware of HIPAA regulations since failure to comply can lead to federal investigations or fines. Follow this decision tree to assess whether you need to become HIPAA compliant or not.
                    </RegularText>

                    <SmallSubtitleText>Who Needs HIPAA Compliance?</SmallSubtitleText>


                        <RegularText sx={{ mb: '4vh' , mt: '1vh'}}>
<strong style={{ color: '#dddddd' }}>Step 1: Determine if you are a Covered Entity.</strong><br />
                        A Covered Entity is a healthcare provider, health plan, or a healthcare clearinghouse that handles medical information which can identify an individual, called protected health information (PHI), as defined under HIPAA law (§160.103). Examples include hospitals, pharmacies, digital clinics, doctors' offices, health insurance companies, and medical billing services.<br /><br />
                        Are you a healthcare provider, health plan, or healthcare clearinghouse?<br />
                        <strong style={{ color: '#dddddd' }}>If Yes:</strong> You need to be HIPAA compliant.<br />
                        <strong style={{ color: '#dddddd' }}>If No:</strong> Proceed to Step 2.<br /><br />

                        <strong style={{ color: '#dddddd' }}>Step 2: Determine if you are a Business Associate.</strong><br />
                        A Business Associate is a vendor or subcontractor who handles PHI on behalf of a Covered Entity or on behalf of another Business Associate, as defined under HIPAA law (§160.103). Examples of Business Associates include AI medical scribes, cloud service providers, data processing companies for clinical trials, AI phone call agents, and electronic health record system providers.<br /><br />
                        Do you handle PHI on behalf of Covered Entities or another Business Associate?<br />
                        <span style={{ color: '#dddddd' }}>If Yes:</span> Proceed to Step 3.<br />
                        <span style={{ color: '#dddddd' }}>If No:</span> You are exempt from HIPAA.<br /><br />

                        <strong style={{ color: '#dddddd' }}>Step 3: Determine if you handle PHI.</strong><br />
                        If you handle PHI that can identify a patient, you need to become HIPAA compliant. However, if the PHI does not contain any of the 18 identifiers below, which are defined by HIPAA’s Safe Harbor provision (§164.502(d), §164.514(a)-(b)), and the information still cannot be used to trace back to the individual it belongs to, then you are exempt from HIPAA regulations.<br /><br />
                        <strong style={{ color: '#dddddd' }}>18 identifiers defined by the Safe Harbor provision:</strong><br />
                        - Names<br />
                        - All geographic subdivisions smaller than a state, including street address, city, county, precinct, ZIP code, and their equivalent geocodes<br />
                        - All elements of dates (except year) for dates that are directly related to an individual, including birth date, admission date, discharge date, death date, and all ages over 89 and all elements of dates (including year) indicative of such age, except that such ages and elements may be aggregated into a single category of age 90 or older<br />
                        - Telephone numbers<br />
                        - Vehicle identifiers and serial numbers, including license plate numbers<br />
                        - Fax numbers<br />
                        - Device identifiers and serial numbers<br />
                        - Email addresses<br />
                        - URLs<br />
                        - SSNs<br />
                        - IP addresses<br />
                        - Medical record numbers<br />
                        - Biometric identifiers, including finger and voice prints<br />
                        - Health plan beneficiary numbers<br />
                        - Full-face photographs and any comparable images<br />
                        - Account numbers<br />
                        - Any other unique identifying number, characteristic, or code<br />
                        - Certificate/license numbers<br /><br />
                        Do you collect, store, or transmit PHI that isn’t de-identified according to the Safe Harbor provision?<br />
                        <span style={{ color: '#dddddd' }}>If Yes:</span> You need to be HIPAA compliant.<br />
                        <span style={{ color: '#dddddd' }}>If No:</span> You are exempt from HIPAA.<br /><br />

                        <strong style={{ color: '#dddddd' }}>Which of the following scenarios requires HIPAA compliance?</strong><br />
                        <span style={{ color: '#dddddd' }}>AI Phone Calling Agents</span><br />
                        Yes, you need to be HIPAA compliant. An AI phone calling service that assists healthcare providers in scheduling appointments, providing patient reminders, and communicating health-related information would need to become HIPAA compliant because it handles PHI during these phone calls and is used at hospitals and clinics (Covered Entities).<br /><br />

                        <span style={{ color: '#dddddd' }}>AI Medical Scribe</span><br />
                        Yes, you need to be HIPAA compliant. An AI medical scribe that assists healthcare professionals in transcribing and documenting patient visits should be HIPAA compliant as it processes PHI to generate medical notes and is used by providers, clinicians, and hospital workers (Covered Entities).<br /><br />

                        <span style={{ color: '#dddddd' }}>Therapy Practice</span><br />
                        You might need to be HIPAA compliant. Because many therapists do not accept insurance payments or conduct certain forms of electronic transactions, they might not be Covered Entities and hence might not be subject to HIPAA regulations. However, those that do accept insurance need to comply with HIPAA. Additionally, some therapists still choose to adopt HIPAA compliant practices because of the sensitive nature of information discussed during therapy sessions.<br /><br />

                        <span style={{ color: '#dddddd' }}>Smart Watch</span><br />
                        No, you don’t need to be HIPAA compliant. A fitness app or wearable that tracks workouts, dietary habits, or overall health metrics (such as Fitbit or Apple Watch) does not need to be HIPAA compliant. The data captured and analyzed by these apps, including steps, calories burned, or sleep patterns, falls outside the jurisdiction of HIPAA protections, as these apps are typically consumer-facing and are not run by healthcare providers or clinics (Covered Entities).
                    </RegularText>

                    <SmallSubtitleText>Conclusion</SmallSubtitleText>
                    <RegularText sx={{ mt: '1vh' }}>
                        Prioritizing HIPAA compliance is vital in the healthcare industry to uphold data security standards and maintain trust with clients. HIPAA regulations apply to (1) Covered Entities, which are healthcare providers, plans, and clearinghouses, and (2) Business Associates, which process PHI on behalf of Covered Entities or other Business Associates.
                    </RegularText>

                    <Box
                        w="100%"
                        borderWidth='1px'
                        borderColor='rgba(255, 255, 255, 0.08)'
                        h="auto"
                        bg="rgba(255, 255, 255, 0.04)"
                        mt="4vh"
                        p="5"
                        borderRadius="md"
                        display="flex"
                        flexDirection={{ base: "column", md: "row" }}
                        alignItems="center"
                    >
                        <Image
                            src={JesusJimenez}
                            alt="Jesus Jimenez"
                            borderRadius="full"
                            boxSize={{ base: "150px", md: "200px" }}
                            mr={{ base: "0", md: "4" }}
                            mb={{ base: "4", md: "0" }}
                        />
                        <VStack align='left'>
                            <SmallSubtitleText>Jesus Jimenez</SmallSubtitleText>
                            <RegularText sx={{ color: '#DDDDDD' }}>Co-Founder of Insight Assurance</RegularText>
                            <RegularText>
                                Jesus Jimenez is the co-founder and partner at <a href="https://insightassurance.com" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'underline' }}>Insight Assurance</a>. Insight Assurance offers compliance audits to ensure that organizations meet all HIPAA requirements. Insight Assurance’s team of experts conducts thorough assessments, offers documentation assistance, and provides ongoing support throughout the auditing process. Delve is proud to be partnered with Insight Assurance to help companies navigate HIPAA compliance with confidence.
                            </RegularText>
                        </VStack>
                    </Box>
                </Box>
            </VStack>
            <Box mt='4rem' mb='3rem' height="1px" bgGradient="linear(to-l, transparent, #dddddd, transparent)" width="100%" />
            <FooterPanel />
        </Box>
    );
};

export default LandingPage;