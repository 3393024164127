import React from 'react';
import { Box, Spacer, HStack, VStack, Image, IconButton, useBreakpointValue, Stack } from '@chakra-ui/react';
import { FaTwitter, FaLinkedin, FaEnvelope } from 'react-icons/fa';
import TitleBackground from '../../assets/titlebackground.png';
import TitleText from '../text/titletext';
import RegularText from '../text/regulartext';
import BookaDemoButton from '../general/bookademobutton';
import BackedByYC from '../general/backedbyyc';
import Logo from '../../assets/logo.png';

const TitlePanel = ({ sx }) => {
  const spacing = useBreakpointValue({ base: 5, md: 70 });
  const paddingX = useBreakpointValue({ base: 4, md: 40 });
  const align = useBreakpointValue({ base: 'center', md: 'left' });
  const direction = useBreakpointValue({ base: 'column', md: 'row' });

  return (
    <Box position="relative" textAlign="center" color="white" w='100%' sx={sx}>
      <VStack ml={paddingX} mr={paddingX} mt={5} spacing={spacing} pr={4} pl={4} pb={16} align={align}>
        <VStack align={{ base: 'center', md: 'start' }} w='100%' spacing={0}>
          <HStack align={{ base: 'center', md: 'start' }}>
            <Image src={Logo} alt="Logo" w='30px' h='auto' objectFit='contain'/>
            <RegularText sx={{color: '#dddddd', fontSize: '2xl', fontWeight: 'bold' }}>Delve</RegularText>
          </HStack>
          <RegularText sx={{mt:'0.4rem'}}>Compliance in days, not months.</RegularText>
        </VStack>

        <Stack direction={direction} spacing={4} w='100%' alignItems={align}>
          <HStack spacing={0} ml={{ base: 0, md: '-12px' }}>
            <IconButton
              as="a"
              href="https://x.com/getdelve"
              aria-label="Twitter"
              icon={<FaTwitter />}
              variant="ghost"
              color="#848484"
              _hover={{ color: '#dddddd' , bg:'transparent'}}
              _focus={{ bg:'transparent'}}
              _active={{ bg:'transparent'}}
              fontSize="xl"
            />
            <IconButton
              as="a"
              href="https://www.linkedin.com/company/getdelve"
              aria-label="LinkedIn"
              icon={<FaLinkedin />}
              variant="ghost"
              color="#848484"
              _hover={{ color: '#dddddd' , bg:'transparent'}}
              _focus={{ bg:'transparent'}}
              _active={{ bg:'transparent'}}
              fontSize="xl"
            />
            <IconButton
              as="a"
              href="mailto:founders@getdelve.com"
              aria-label="Email"
              icon={<FaEnvelope />}
              variant="ghost"
              color="#848484"
              _hover={{ color: '#dddddd' , bg:'transparent'}}
              _focus={{ bg:'transparent'}}
              _active={{ bg:'transparent'}}
              fontSize="xl"
            />
          </HStack>
          <Spacer display={{ base: 'none', md: 'block' }} />
          <RegularText sx={{mt:'1rem', textAlign: { base: 'center', md: 'left' }}}>© 2024 Delve Technologies Inc.</RegularText>
        </Stack>
      </VStack>
    </Box>
  );
};

export default TitlePanel;