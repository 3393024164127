import React from 'react';
import { Text } from '@chakra-ui/react';

const TitleText = ({ children, sx }) => {
  return (
    <Text
      sx={{
        ...sx,
        fontWeight: 'bold',
        fontSize: ['md', 'xl', '2xl', '3xl'],
        bgClip: 'text',
        color: '#dddddd',
        display: 'inline',
        WebkitBoxDecorationBreak: 'clone',
        boxDecorationBreak: 'clone',
        lineHeight: '1.2',
      }}
    >
      {children}
    </Text>
  );
};

export default TitleText;