import React from "react";
import { Button } from "@chakra-ui/react";
import RegularText from "../text/regulartext";

const CustomButton = ({ text, onClick, isDisabled, children, key, sx }) => {
  return (
    <Button
      sx={sx}
      h="35px"
      onClick={() => (window.location.href = "https://www.cal.com/team/getdelve/demo")}
      variant="unstyled"
      bg="#E6E6E6"
      borderRadius="xl"
      pl={3}
      pr={3}
      _hover={{ bg: "white", boxShadow: "0 0 14px rgba(255, 255, 255, 0.5)" }}
      boxShadow="0 0 14px rgba(255, 255, 255, 0.3)"
      _active={{ bg: "white", boxShadow: "0 0 18px rgba(255, 255, 255, 0.5)" }}
    >
      <RegularText sx={{ fontSize: "md", color: "#101012", fontWeight: "normal" }}>Book a demo</RegularText>
    </Button>
  );
};

export default CustomButton;
