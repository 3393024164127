import React from 'react';
import { Box, VStack, Image, Grid, GridItem, useBreakpointValue, Spacer } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';
import ProductImage from '../../assets/product.png';
import SmallSubtitleText from '../text/smallsubtitletext';

const TitlePanel = () => {
  const marginX = useBreakpointValue({ base: 4, md: 40 });
  const paddingX = useBreakpointValue({ base: 4, md: 4 });
  const paddingBottom = useBreakpointValue({ base: 8, md: 16 });
  const spacing = useBreakpointValue({ base: 4, md: 70 });
  const gap = useBreakpointValue({ base: 4, md: 10 }); // Adjust the gap as needed
  

  return (
    <Box ml={marginX} mr={marginX} mt={5} spacing={spacing} pr={paddingX} pl={paddingX} pb={paddingBottom} align='left' color="white">
        <SubtitleText>
            The fastest way to get compliant
        </SubtitleText>

        <Grid templateColumns={{ base: "1fr", lg: "1fr 1fr" }} gap={gap} mt={10}>
            <GridItem display="flex" flexDirection="column">
                <VStack align="left" spacing={8} height="100%">
                    <Box flex="1">
                        <SmallSubtitleText>
                            1. Integrate
                        </SmallSubtitleText>
                        <Box ml='2vw' mt='2vh'>
                        <ul style={{ listStyleType: 'circle', color: '#848484' }}>
                            <li>
                                <RegularText>
                                    Connect AWS, OpenAI, Github, and other platforms you use
                                </RegularText>
                            </li>
                            <li>
                                <RegularText>
                                Detect compliance issues automatically with Delve
                                </RegularText>
                            </li>
                        </ul>
                        </Box>

                    </Box>
                    <Spacer />
                    <Box flex="1">
                        <SmallSubtitleText>
                            2. Automate
                        </SmallSubtitleText>
                        <Box ml='2vw' mt='2vh'>
                        <ul style={{ listStyleType: 'circle', color: '#848484' }}>
                            <li>
                                <RegularText>
                                    Fix compliance issues with AI that understands your company
                                </RegularText>
                            </li>
                            <li>
                                <RegularText>
                                    Breeze though security questionaires and documents
                                </RegularText>
                            </li>
                        </ul>
                        </Box>
                    </Box>
                    <Spacer />
                    <Box flex="1">
                        <SmallSubtitleText>
                            3. Celebrate
                        </SmallSubtitleText>
                        <Box ml='2vw' mt='2vh'>
                        <ul style={{ listStyleType: 'circle', color: '#848484' }}>
                            <li>
                                <RegularText>
                                    Advertise your compliance with a free status page and badge
                                </RegularText>
                            </li>
                            <li>
                                <RegularText>
                                    Win customer trust and celebrate closed deals
                                </RegularText>
                            </li>
                        </ul>
                        </Box>
                    </Box>
                </VStack>
            </GridItem>
            <GridItem mt={{ base: 6, md: 0 }}>
                <Image src={ProductImage} alt="Product" objectFit="cover" width="100%" height="100%" borderRadius="lg" />
            </GridItem>
        </Grid>
    </Box>
  );
};

export default TitlePanel;