import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import NavBar from '../components/general/navbar';
import TitlePanel from '../components/panels/titlepanel';
import FooterPanel from '../components/panels/footerpanel';
import CalltoActionPanel from '../components/panels/calltoactionpanel';
import BlogPanel from '../components/panels/blogpanel';
import CustomerPanel from '../components/panels/customerpanel';
import FeaturePanel from '../components/panels/featurepanel';
import ProductPanel from '../components/panels/productpanel';

const LandingPage = ({ }) => {
  return (
    <Box bg="#101012" w='100%' h='auto'>
        <Box position="fixed" top="0" left="0" width="100%" zIndex="1000">
            <NavBar />
        </Box>
        <VStack spacing={20} w='100%'>
            <TitlePanel />
            <CustomerPanel />
            <ProductPanel />
            <FeaturePanel />
            <BlogPanel />
            <CalltoActionPanel />
            <FooterPanel />
        </VStack>
    </Box>
  );
};

export default LandingPage;