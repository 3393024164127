import React from 'react';
import { HStack, Image, useBreakpointValue } from '@chakra-ui/react';
import RegularText from '../text/regulartext';
import YCLogo from '../../assets/yclogo.png';

const BackedByYC = ({ sx }) => {
  const fontSize = useBreakpointValue({ base: '0.8rem', md: '1.1rem' });
  const imageWidth = useBreakpointValue({ base: '90px', md: '140px' });
  const paddingX = useBreakpointValue({ base: 2, md: 3 });
  const height = useBreakpointValue({ base: '30px', md: '40px' });

  return (
    <HStack 
      sx={sx} 
      h={height} 
      variant="unstyled" 
      borderRadius='xl' 
      pl={paddingX} 
      pr={paddingX} 
      bg='transparent' 
      borderColor="transparent" 
      borderWidth="1px" 
      spacing={0.5} 
      display="inline-flex"
    >
      <RegularText sx={{ fontSize: fontSize, color: '#F26625', fontWeight: 'normal', mb: '3px' }}>
        Backed by
      </RegularText>
      <Image src={YCLogo} alt="YC Logo" width={imageWidth} height="auto" />
    </HStack>
  );
};

export default BackedByYC;