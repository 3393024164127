import React from 'react';
import { Box, HStack, VStack, Image, Button, useBreakpointValue } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';
import SelinKocalar from '../../assets/selinkocalar.png';
import BenefitsofHIPAA from '../../assets/blog/benefitsofhipaa.png';
import TwoMinuteGuide from '../../assets/blog/twominuteguide.png';
import ExpertInterview from '../../assets/blog/expertinterview.png';
import { FaExternalLinkAlt } from 'react-icons/fa';
import SteveRyan from '../../assets/blog/SteveRyan.png';
import NickAccomando from '../../assets/blog/NickAccomando.png';
import ChaseParsons from '../../assets/blog/ChaseParsons.png';

const BlogPanel = () => {
  const marginX = useBreakpointValue({ base: 4, md: 40 });
  const paddingX = useBreakpointValue({ base: 4, md: 4 });
  const paddingBottom = useBreakpointValue({ base: 8, md: 16 });
  const spacing = useBreakpointValue({ base: 4, md: 70 });
  const stackDirection = useBreakpointValue({ base: 'column', md: 'row' });
  const marginBottom = useBreakpointValue({ base: 0, md: -10 });

  return (
    <Box ml={marginX} mr={marginX} mt={5} spacing={spacing} pr={paddingX} pl={paddingX} pb={paddingBottom} align='left' color="white">
      <HStack as={stackDirection} justifyContent="space-between" alignItems={stackDirection === 'column' ? 'flex-start' : 'center'} spacing={4}>
        <SubtitleText>
          Learn from the experts
        </SubtitleText>
        <Button
          color='#848484'
          variant="unstyled" 
          borderRadius="xl"
          _hover={{ color: '#dddddd' }}
          h='35px'
          p={3}
          display="flex"
          justifyContent="center"
          alignItems="center"
          fontWeight='normal'
          rightIcon={<FaExternalLinkAlt style={{ fontSize: '12px' }} />}
          mb={marginBottom}
          onClick={() => window.location.href='/blog'}
        >
          View all
        </Button>
      </HStack>
      <Box display="grid" gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }} gap={4} mt={6}>
        <VStack 
          align='left' 
          spacing={0} 
          bg="rgba(255, 255, 255, 0.04)" 
          borderRadius='xl' 
          borderColor="rgba(255, 255, 255, 0.08)" 
          borderWidth="1px" 
          p={4} 
          boxShadow='0 0 14px rgba(255, 255, 255, 0.05)'
          cursor="pointer"
          onClick={() => window.location.href='/blog/the-benefits-of-hipaa-compliance'}
        >
          <Box 
            border="1px solid rgba(255, 255, 255, 0.08)" 
            borderRadius='lg' 
            width="100%" 
            height="100%" 
            overflow="hidden"
          >
            <Image 
              src={BenefitsofHIPAA} 
              alt="Benefits of HIPAA compliance" 
              width="100%" 
              height="100%" 
              borderRadius='lg' 
              objectFit="cover"
              transition="transform 0.3s ease"
            />
          </Box>
          <RegularText sx={{color:'#dddddd', fontSize:'lg', mt:4 }}>
            The benefits of HIPAA compliance
          </RegularText>
          <RegularText>
            April 8th, 2024
          </RegularText>
          <HStack mt={6} minWidth="0">
  <Box width="45px" height="45px" borderRadius="full" overflow="hidden" flexShrink={0}>
    <Image 
      src={SteveRyan} 
      alt="Steve Ryan" 
      width="45px" 
      height="45px" 
      objectFit="cover" 
    />
  </Box>
  <VStack align='left' spacing={0}>
    <RegularText sx={{color:'#dddddd'}}>
      Steve Ryan
    </RegularText>
    <RegularText>
      Head of Healthcare Services, BARR Advisory
    </RegularText>
  </VStack>
</HStack>
        </VStack>

        <VStack spacing={4} justifyContent='stretch'>
          <HStack w='100%' flex='1' bg="rgba(255, 255, 255, 0.04)" borderRadius='xl' borderColor="rgba(255, 255, 255, 0.08)" borderWidth="1px" p={4} boxShadow='0 0 14px rgba(255, 255, 255, 0.05)' spacing={4} cursor="pointer" onClick={() => window.location.href='/blog/expert-interview-hipaa-compliance-for-saas-companies'}>
            <Box border="1px solid rgba(255, 255, 255, 0.08)" borderRadius='lg' width="30%" height="100%" overflow="hidden">
              <Image 
                src={ExpertInterview} 
                alt="Expert Interview" 
                width="100%" 
                height="100%" 
                borderRadius='lg' 
                objectFit="cover"
              />
            </Box>
            <VStack align='left' spacing={0}>
              <RegularText sx={{color:'#dddddd', fontSize:'lg'}}>
                Expert interview: HIPAA compliance for SaaS companies
              </RegularText>
              <RegularText>
                March 21st, 2024
              </RegularText>
              <HStack mt={6} minWidth="0">
  <Box width="45px" height="45px" borderRadius="full" overflow="hidden" flexShrink={0}>
    <Image 
      src={NickAccomando} 
      alt="Nick Accomando" 
      width="45px" 
      height="45px" 
      objectFit="cover" 
    />
  </Box>
  <VStack align='left' spacing={0}>
    <RegularText sx={{color:'#dddddd'}}>
      Nick Accomando
    </RegularText>
    <RegularText>
      Chief Compliance & Privacy Officer, Flatiron Health
    </RegularText>
  </VStack>
</HStack>
            </VStack>
          </HStack>

          <HStack w='100%' flex='1' bg="rgba(255, 255, 255, 0.04)" borderRadius='xl' borderColor="rgba(255, 255, 255, 0.08)" borderWidth="1px" p={4} boxShadow='0 0 14px rgba(255, 255, 255, 0.05)' spacing={4} cursor="pointer" onClick={() => window.location.href='/blog/the-two-minute-guide-to-hipaa-compliance'}>
            <Box border="1px solid rgba(255, 255, 255, 0.08)" borderRadius='lg' width="30%" height="100%" overflow="hidden">
              <Image 
                src={TwoMinuteGuide} 
                alt="Two Minute Guide" 
                width="100%" 
                height="100%" 
                borderRadius='lg' 
                objectFit="cover"
                transition="transform 0.3s ease"
              />
            </Box>                
            <VStack align='left' spacing={0}>
              <RegularText sx={{color:'#dddddd', fontSize:'lg'}}>
                The 2-minute guide to HIPAA compliance
              </RegularText>
              <RegularText>
                February 28th, 2024
              </RegularText>
              <HStack mt={6} minWidth="0">
  <Box width="45px" height="45px" borderRadius="full" overflow="hidden" flexShrink={0}>
    <Image 
      src={ChaseParsons} 
      alt="Dr. Chase Parsons" 
      width="45px" 
      height="45px" 
      objectFit="cover" 
    />
  </Box>
  <VStack align='left' spacing={0}>
    <RegularText sx={{color:'#dddddd'}}>
      Dr. Chase Parsons
    </RegularText>
    <RegularText>
      Chief Medical Information Officer, Boston Children's Hospital
    </RegularText>
  </VStack>
</HStack>
            </VStack>
          </HStack>
        </VStack>
      </Box>
    </Box>
  );
};

export default BlogPanel;