import React from "react";
import { Button, Box } from "@chakra-ui/react";
import { motion } from "framer-motion";
import RegularText from "../text/regulartext";
import posthog from "posthog-js";

const MotionButton = motion(Button);

const CustomButton = ({ text, onClick, isDisabled, children, key, sx }) => {
  const handleClick = () => {
    posthog.capture("Book a Demo Clicked");
    window.location.href = "https://www.cal.com/team/getdelve/demo";
  };

  return (
    <MotionButton
      onClick={handleClick}
      sx={{
        ...sx,
        background: "conic-gradient(from 0deg, #E6E6E6, #C0C0C0)",
      }}
      h="45px"
      variant="unstyled"
      borderRadius="2xl"
      p={0.9}
      animate={{
        background: ["conic-gradient(from 0deg, #F25C2C, #FFB9B9)", "conic-gradient(from 360deg, #F25C2C, #FFB9B9)"],
      }}
      transition={{ repeat: Infinity, duration: 5, ease: "linear" }}
    >
      <Box
        bgGradient="linear(to-b, #F25C2C, #AB3402)"
        w="100%"
        h="100%"
        borderRadius="2xl"
        pl={6}
        pr={6}
        display="flex"
        alignItems="center"
        justifyContent="center"
      >
        <RegularText sx={{ fontSize: "md", color: "#dddddd", fontWeight: "medium" }}>Book a demo</RegularText>
      </Box>
    </MotionButton>
  );
};

export default CustomButton;
