import React from "react";
import { Box, Button, HStack, Image, Spacer, useBreakpointValue, VStack, IconButton } from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon } from "@chakra-ui/icons";
import Logo from "../../assets/logo.png";
import RegularText from "../text/regulartext";

const NavBar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleMenu = () => setIsOpen(!isOpen);

  const display = useBreakpointValue({ base: "none", md: "flex" });
  const mobileDisplay = useBreakpointValue({ base: "flex", md: "none" });

  return (
    <Box>
      <HStack
        ml={{ base: 4, md: 20 }}
        mr={{ base: 4, md: 20 }}
        mt={5}
        spacing={5}
        bg="rgba(10, 10, 10, 0.6)"
        borderRadius="xl"
        borderColor="rgba(255, 255, 255, 0.08)"
        borderWidth="1px"
        h="auto"
        p={4}
        boxShadow="0 0 14px rgba(255, 255, 255, 0.05)"
        backdropFilter="blur(10px)"
      >
        <Button onClick={() => (window.location.href = "/")} variant="unstyled" mr="100px">
          <HStack w="100%" h="100%">
            <Image src={Logo} alt="Logo" w="30px" h="auto" objectFit="contain" />
            <RegularText sx={{ color: "#dddddd", fontSize: "2xl" }}>Delve</RegularText>
          </HStack>
        </Button>

        <Spacer display={display} />

        <HStack display={display} spacing={5}>
          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "/")}
          >
            Home
          </Button>

          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "/blog")}
          >
            Blog
          </Button>

          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "mailto:founders@getdelve.com")}
          >
            Contact
          </Button>
        </HStack>

        <Spacer display={display} />

        <HStack display={display} spacing={5}>
          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "https://dashboard.getdelve.com/")}
          >
            Log in
          </Button>

          <Button
            h="35px"
            onClick={() => (window.location.href = "https://www.cal.com/team/getdelve/demo")}
            variant="unstyled"
            bg="#E6E6E6"
            borderRadius="xl"
            pl={3}
            pr={3}
            _hover={{ bg: "white", boxShadow: "0 0 14px rgba(255, 255, 255, 0.5)" }}
            // boxShadow='0 0 14px rgba(255, 255, 255, 0.3)'
            _active={{ bg: "white", boxShadow: "0 0 18px rgba(255, 255, 255, 0.5)" }}
            border="1px solid"
            borderColor="linear-gradient(to bottom, gray, white)"
          >
            <RegularText sx={{ fontSize: "md", color: "#101012", fontWeight: "normal" }}>Book a demo</RegularText>
          </Button>
        </HStack>

        <Spacer display={mobileDisplay}></Spacer>

        <IconButton
          display={mobileDisplay}
          icon={isOpen ? <CloseIcon /> : <HamburgerIcon />}
          onClick={toggleMenu}
          variant="unstyled"
          aria-label="Toggle Navigation"
          color="#dddddd"
        />
      </HStack>

      {isOpen && (
        <VStack
          ml={{ base: 4, md: 20 }}
          mr={{ base: 4, md: 20 }}
          bg="rgba(10, 10, 10, 0.6)"
          borderRadius="xl"
          borderColor="rgba(255, 255, 255, 0.08)"
          borderWidth="1px"
          mt={2}
          p={4}
          boxShadow="0 0 14px rgba(255, 255, 255, 0.05)"
          backdropFilter="blur(10px)"
          spacing={4}
        >
          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "/")}
          >
            Home
          </Button>

          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "/blog")}
          >
            Blog
          </Button>

          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "mailto:founders@getdelve.com")}
          >
            Contact
          </Button>

          <Button
            color="#848484"
            variant="unstyled"
            borderRadius="xl"
            _hover={{ color: "#dddddd" }}
            h="35px"
            p={3}
            display="flex"
            justifyContent="center"
            alignItems="center"
            fontWeight="normal"
            onClick={() => (window.location.href = "https://dashboard.getdelve.com/")}
          >
            Log in
          </Button>

          <Button
            h="35px"
            onClick={() => (window.location.href = "https://www.cal.com/team/getdelve/demo")}
            variant="unstyled"
            bg="#E6E6E6"
            borderRadius="xl"
            pl={3}
            pr={3}
            _hover={{ bg: "white", boxShadow: "0 0 14px rgba(255, 255, 255, 0.5)" }}
            boxShadow="0 0 14px rgba(255, 255, 255, 0.3)"
            _active={{ bg: "white", boxShadow: "0 0 18px rgba(255, 255, 255, 0.5)" }}
          >
            <RegularText sx={{ fontSize: "md", color: "#101012", fontWeight: "normal" }}>Book a demo</RegularText>
          </Button>
        </VStack>
      )}
    </Box>
  );
};

export default NavBar;
