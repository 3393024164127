import React from 'react';
import { Box, Image, VStack, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import TitleBackground from '../../assets/titlebackground.png';
import TitleText from '../text/titletext';
import RegularText from '../text/regulartext';
import FancyBookaDemoButton from '../general/fancybookademobutton';
import BackedByYC from '../general/backedbyyc';
import DashboardScreenshot from '../../assets/dashboardscreenshot.png';
import LargerRegularText from '../text/largerregulartext';

const TitlePanel = () => {
  const topPosition = useBreakpointValue({ base: '40%', sm: '30%', md: '20%', lg: '18%', xl: '15%' });
  const paddingX = useBreakpointValue({ base: 4, md: 20 });
  const hStackHeight = useBreakpointValue({ base: 'auto', md: '650px' });
  const lineHeight = useBreakpointValue({ base: '1.2', md: '1.0' });

  const MotionBox = motion(Box);

  return (
    <MotionBox
      position="relative"
      textAlign="center"
      color="white"
      h='auto'
      w='100%'
      pt='18vh'
      px={paddingX}
      backgroundImage={`url(${TitleBackground})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
    >
      <Box w='auto' mb={8}>
        <BackedByYC/>
      </Box>
      <MotionBox
        initial={{ opacity: 0, y: 80 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.5 }}
        sx={{ mb: { base: '1rem', md: '1rem' }, lineHeight: lineHeight, overflowY: 'visible' }}
      >
        <VStack spacing={0}>
          <TitleText  >
            Startup-first compliance
          </TitleText>
        </VStack>

      </MotionBox>

      <MotionBox
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.6 }}
        sx={{ mt: { base: '0.5rem', md: '1rem' } }}
      >
        <LargerRegularText sx={{ color: 'rgba(255, 255, 255, 0.6)' }}>
          HIPAA and SOC2 in days, not months.
        </LargerRegularText>
      </MotionBox>

      <MotionBox
        initial={{ opacity: 0, y: 120 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.7 }}
        sx={{ mt: { base: '4rem', md: '8rem' }, mb: { base: '1.5rem', md: '3rem' } }}
      >
        <FancyBookaDemoButton />
      </MotionBox>

      <MotionBox
  initial={{ opacity: 0, y: 200 }}
  animate={{ opacity: 1, y: 0 }}
  transition={{ duration: 1.7 }}
  mt={5}
  h="auto"
  mb={20}
  spacing={5}
  bg="rgba(255, 255, 255, 0.04)"
  borderRadius='xl'
  borderColor="rgba(255, 255, 255, 0.08)"
  borderWidth="1px"
  p={4}
  boxShadow='0 0 14px rgba(255, 255, 255, 0.05)'
  backdropFilter='blur(10px)'
>
  <Image 
    src={DashboardScreenshot} 
    alt="Dashboard Screenshot" 
    width="100%" 
    height="auto" 
    borderRadius="lg" 
    border="1px solid rgba(255, 255, 255, 0.1)" 
  />
</MotionBox>
    </MotionBox>
  );
};

export default TitlePanel;