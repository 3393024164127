import React from 'react';
import { Text } from '@chakra-ui/react';

const SubtitleText = ({ children, sx }) => {
  return (
    <Text
      sx={{
        ...sx,
        fontWeight: 'bold',
        fontSize: ['xl', '3xl', '5xl', '6xl'],
        bgClip: 'text',
        bgGradient: 'linear(to-b, white, #777777)',
        display: 'inline',
        WebkitBoxDecorationBreak: 'clone',
        boxDecorationBreak: 'clone',
      }}
    >
      {children}
    </Text>
  );
};

export default SubtitleText;