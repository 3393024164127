import React from 'react';
import { Box, Grid, VStack, Image, Spacer, HStack, useBreakpointValue, Button } from '@chakra-ui/react';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';
import { FaExternalLinkAlt } from 'react-icons/fa';

import SteveRyan from '../../assets/blog/SteveRyan.png';
import NickAccomando from '../../assets/blog/NickAccomando.png';
import ChaseParsons from '../../assets/blog/ChaseParsons.png';
import JesusJimenez from '../../assets/blog/JesusJimenez.png';

import BenefitsofHIPAA from '../../assets/blog/SteveRyanBlog.png';
import ExpertInterview from '../../assets/blog/NickAccomandoBlog.png';
import TwoMinuteGuide from '../../assets/blog/ChaseParsonsBlog.png';
import WhoDoesHIPAAApplyTo from '../../assets/blog/JesusJimenezBlog.png';

const posts = [
  {
    title: "The benefits of HIPAA compliance",
    date: "April 8th, 2024",
    author: "Steve Ryan",
    authorTitle: "Head of Healthcare Services, BARR Advisory",
    image: BenefitsofHIPAA,
    authorImage: SteveRyan,
    url: "/blog/the-benefits-of-hipaa-compliance"
  },
  {
    title: "Expert interview: HIPAA compliance for SaaS companies",
    date: "March 21st, 2024",
    author: "Nick Accomando",
    authorTitle: "Chief Compliance & Privacy Officer, Flatiron Health",
    image: ExpertInterview,
    authorImage: NickAccomando,
    url: "/blog/expert-interview-hipaa-compliance-for-saas-companies"
  },
  {
    title: "The 2-minute guide to HIPAA compliance",
    date: "February 28th, 2024",
    author: "Dr. Chase Parsons",
    authorTitle: "Chief Medical Information Officer, Boston Children's Hospital",
    image: TwoMinuteGuide,
    authorImage: ChaseParsons,
    url: "/blog/the-two-minute-guide-to-hipaa-compliance"
  },
  {
    title: "Who does HIPAA apply to?",
    date: "February 20th, 2024",
    author: "Jesus Jimenez",
    authorTitle: "Co-Founder of Insight Assurance",
    image: WhoDoesHIPAAApplyTo,
    authorImage: JesusJimenez,
    url: "/blog/who-does-hipaa-apply-to"
  },
];

const BlogPanel = () => {
  const marginX = useBreakpointValue({ base: 4, md: 40 });
  const paddingX = useBreakpointValue({ base: 4, md: 4 });
  const paddingBottom = useBreakpointValue({ base: 8, md: 16 });
  const spacing = useBreakpointValue({ base: 4, md: 70 });
  const columns = useBreakpointValue({ base: 1, md: 3 });

  return (
    <Box ml={marginX} mr={marginX} mt={5} pr={paddingX} pl={paddingX} pb={paddingBottom} align='left' color="white">
      <Grid templateColumns={`repeat(${columns}, 1fr)`} gap={4} mt={6}>
        {posts.map((post, index) => (
          <VStack
            key={index}
            align='left'
            spacing={0}
            bg="rgba(255, 255, 255, 0.04)"
            borderRadius='xl'
            borderColor="rgba(255, 255, 255, 0.08)"
            borderWidth="1px"
            p={4}
            boxShadow='0 0 14px rgba(255, 255, 255, 0.05)'
            onClick={() => window.location.href = post.url}
            cursor="pointer"
          >
            <Box border="1px solid rgba(255, 255, 255, 0.08)" borderRadius='lg' width="100%" height="auto" overflow="hidden">
              <Image 
                src={post.image} 
                alt={post.title} 
                width="100%" 
                height="100%" 
                borderRadius='lg' 
                objectFit="contain"
                transition="transform 0.3s ease"
              />
            </Box>
            <RegularText sx={{color:'#dddddd', fontSize:'lg', mt:4 }}>
              {post.title}
            </RegularText>
            <RegularText>
              {post.date}
            </RegularText>
            <Spacer />
            <HStack mt={6} minWidth="0" alignItems="center">
              <Box width="45px" height="45px" borderRadius="full" overflow="hidden" flexShrink={0}>
                <Image 
                  src={post.authorImage}
                  alt={post.author}
                  width="45px" 
                  height="45px" 
                  objectFit="cover" 
                />
              </Box>
              <VStack align='left' spacing={0} flex="1">
                <RegularText sx={{color:'#dddddd'}}>
                  {post.author}
                </RegularText>
                <RegularText>
                  {post.authorTitle}
                </RegularText>
              </VStack>
            </HStack>
          </VStack>
        ))}
      </Grid>
    </Box>
  );
};

export default BlogPanel;