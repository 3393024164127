import React from 'react';
import { Box, Image, VStack, HStack, useBreakpointValue } from '@chakra-ui/react';
import BlandAI from '../../assets/customers/blandai.png';
import FellaHealth from '../../assets/customers/fellahealth.png';
import Automat from '../../assets/customers/automat.png';
import CanonicalChat from '../../assets/customers/canonicalchat.png';
import Toma from '../../assets/customers/toma.png';

const TitlePanel = () => {
  const marginX = useBreakpointValue({ base: 4, md: 40 });
  const paddingX = useBreakpointValue({ base: 4, md: 4 });
  const paddingBottom = useBreakpointValue({ base: 8, md: 16 });
  const isMobile = useBreakpointValue({ base: true, md: false });

  return (
    <Box ml={marginX} mr={marginX} pr={paddingX} pl={paddingX} pb={paddingBottom} align='left' color="white">
      {isMobile ? (
        <VStack width="100%" gap={4} align="center">
          <HStack gap={4} justify="center">
            <Image src={BlandAI} alt="Bland AI" width="42%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
            <Image src={Automat} alt="Automat" width="31%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
          </HStack>
          <HStack gap={4} justifyContent="space-between">
            <Image src={Toma} alt="Toma" width="18%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
            <Image src={CanonicalChat} alt="Canonical Chat" width="57%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
            <Image src={FellaHealth} alt="Fella Health" width="12%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
          </HStack>
        </VStack>
      ) : (
        <HStack gap={10} width="100%" justifyContent="space-between">
          <Image src={BlandAI} alt="Bland AI" width="20%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
          <Image src={FellaHealth} alt="Fella Health" width="6%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
          <Image src={Automat} alt="Automat" width="16%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
          <Image src={Toma} alt="Toma" width="10%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
          <Image src={CanonicalChat} alt="Canonical Chat" mb='-10px' width="20%" height="auto" style={{ filter: 'grayscale(100%) invert(100%) brightness(1000%) contrast(1000%)', opacity: 0.3 }} />
        </HStack>
      )}
    </Box>
  );
};

export default TitlePanel;