import React from 'react';
import { Box, VStack } from '@chakra-ui/react';
import NavBar from '../components/general/navbar';
import BlogHeader from '../components/panels/blogheader';
import BlogBody from '../components/panels/blogbody';
import FooterPanel from '../components/panels/footerpanel';

const LandingPage = ({ }) => {
  return (
    <Box bg="#101012" w='100%' h='auto'>
        <Box position="fixed" top="0" left="0" width="100%" zIndex="1000">
            <NavBar />
        </Box>
        <VStack spacing={0} w='100%'>
            <BlogHeader />
            <BlogBody />
            <Box mt='4rem' mb='3rem' height="1px" bgGradient="linear(to-l, transparent, #dddddd, transparent)" width="100%" />
            <FooterPanel/>
        </VStack>
    </Box>
  );
};

export default LandingPage;