import React from 'react';
import { Box, Image, useBreakpointValue, VStack } from '@chakra-ui/react';
import BookaDemoButton from '../general/fancybookademobutton';
import CalltoActionBackground from '../../assets/calltoactionbackground.png';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';


const TitlePanel = () => {
  const textAlign = useBreakpointValue({ base: 'center', md: 'center' });
  const buttonMarginTop = useBreakpointValue({ base: '1rem', md: '2rem' });

  return (
    <Box position="relative" textAlign={textAlign} color="white" mt='2vh'>
      <VStack spacing={0}>
        <SubtitleText>
          Ready to get compliant?
        </SubtitleText>


        <BookaDemoButton sx={{ mt: buttonMarginTop, mb:'3vh' }} />
        <RegularText >
          White-glove onboarding and a Slack channel with the founders.
        </RegularText>
      </VStack>

        <Image src={CalltoActionBackground} alt="Title Background" width="100%" height="auto"/>
    </Box>
  );
};

export default TitlePanel;