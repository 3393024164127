import React from 'react';
import { Box, VStack, useBreakpointValue, Image } from '@chakra-ui/react';
import NavBar from '../../components/general/navbar';
import FooterPanel from '../../components/panels/footerpanel';
import RegularText from '../../components/text/regulartext';
import SubtitleText from '../../components/text/subtitletext';
import BlogImage from '../../assets/blog/SteveRyanBlog.png';
import SmallSubtitleText from '../../components/text/smallsubtitletext';
import SteveRyan from '../../assets/blog/SteveRyan.png';
import SmallerSubtitleText from '../../components/text/smallersubtitletext';

const LandingPage = () => {
    const paddingX = useBreakpointValue({ base: 8, md: 60 }); // Adjusted paddingX to match other pages

    return (
        <Box bg="#101012" w="100%" h="auto">
            <Box position="fixed" top="0" left="0" width="100%" zIndex="1000">
                <NavBar />
            </Box>
            <VStack spacing={0} w="100%">
                <Box
                    position="relative"
                    textAlign="left"
                    color="white"
                    h="auto"
                    w="100%"
                    pt="20vh"
                    pr={paddingX}
                    pl={paddingX}
                    pb="5vh"
                >
                    <RegularText 
                        sx={{ mb: '2vh', mt: '1vh', cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                        onClick={() => window.location.href = '/blog'}
                    >
                        <span style={{ marginRight: '8px' }}>←</span>
                        Back to all blogs
                    </RegularText>
                    <Image src={BlogImage} alt="Blog" w="100%" borderRadius="xl" mb={10} />
                    <SubtitleText sx={{ lineHeight: '1'}}>The benefits of HIPAA compliance</SubtitleText>
                    <RegularText sx={{ color: '#dddddd', mt: '2vh', mb: '4vh' }}>April 8th, 2024</RegularText>




                    <SmallSubtitleText>Overview</SmallSubtitleText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                    According to the Department of Health and Human Services Office for Civil Rights (OCR), there has been a considerable upward trend in healthcare data breaches since the office began tracking data breach statistics in 2009. Now more than ever, protecting patient data is paramount—and the <span style={{ textDecoration: 'underline', color: '#dddddd', cursor: 'pointer' }} onClick={() => window.location.href = '/blog/the-two-minute-guide-to-hipaa-compliance'}>Health Insurance Portability and Accountability Act (HIPAA)</span> is key to providing patients with security and privacy. 
</RegularText>
<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
HIPAA is a federal law passed in 1996 designed to protect patient health data. Covered entities, including healthcare providers, health insurance companies, healthcare clearinghouses, and any business associates of a covered entity that use or disclose protected health information (PHI), are <span style={{ textDecoration: 'underline', color: '#dddddd', cursor: 'pointer' }} onClick={() => window.location.href = '/blog/who-does-hipaa-apply-to'}>required by law to comply with HIPAA</span>.
</RegularText>




<SmallSubtitleText>Breakdown of HIPAA
</SmallSubtitleText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
There are three key parts of HIPAA—the Privacy Rule, the Security Rule, and the Breach Notification Rule.
</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
The HIPAA Privacy Rule ensures that PHI is protected throughout the flow of data by addressing when and how individual health information can be used or disclosed. During the process of providing and receiving quality healthcare, health data has to flow from one entity or individual to another. If you’ve been to the doctor recently, you can probably visualize this flow. The nurse tells the doctor about why you came in today, the doctor visits you and perhaps gives you a diagnosis and prescription, your doctor’s office sends your prescription information to a pharmacy, and your health insurance gets a summary of your visit in order to provide coverage. And with patient portals and apps, all of that data can be uploaded and available to you electronically, too. 
</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
The HIPAA Security Rule requires all covered entities to ensure the security, confidentiality, integrity, and availability of electronic PHI; to detect and protect against security threats, impermissible uses, and disclosures; and to certify compliance within their workforce.
</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
Lastly, the HIPAA Breach Notification Rule requires covered entities and their business associates to provide notification when PHI is compromised as a result of a breach. 
</RegularText>

<SmallSubtitleText>Benefits of HIPAA compliance
</SmallSubtitleText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
Compliance with HIPAA is often more than just a legal requirement for organizations—it’s a way to protect patient data, ensure the integrity of healthcare systems, and communicate to patients, partners, and stakeholders that the organization takes security seriously. 
</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
Let’s take a closer look at some key benefits of HIPAA compliance: 
</RegularText>










<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
<strong style={{ color: '#dddddd' }}>Avoid penalties</strong><br/>
HIPAA is more than just a compliance framework—it’s a federal law. Non-compliance with HIPAA regulations can lead to severe penalties. Organizations that fail to protect PHI may face hefty fines, reputational damage, and even criminal charges. By complying with HIPAA standards, organizations can ensure they operate legally and mitigate the potential risks of legal penalties. 
</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
<strong style={{ color: '#dddddd' }}>Build trust with patients and customers</strong><br/>
When companies adhere to HIPAA regulations, patients feel more secure in sharing their sensitive information. HIPAA was enacted in order to protect patient privacy, but it goes beyond just patient trust—complying with HIPAA can also build trust with partners and important stakeholders. This trust is foundational for both patient relationships and key business strategies.

</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
<strong style={{ color: '#dddddd' }}>Mitigate risk</strong><br/>
HIPAA requires organizations to implement robust security measures to safeguard electronic PHI (ePHI). This includes encryption, access controls, regular audits, and comprehensive risk assessments. Some specific policies that should be put in place to prevent the unauthorized disclosure of PHI and ensure patient data is protected include those that establish procedures for safeguarding access to PHI, responding to HIPAA violations when they occur, and implementing HIPAA training for everyone who has access to PHI as part of their day-to-day roles. By following these processes, organizations strengthen their compliance postures, reduce the likelihood of data breaches, and mitigate potential threats to PHI. 
</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
<strong style={{ color: '#dddddd' }}>Streamline operations</strong><br/>
To comply with HIPAA, organizations are required to adopt streamlined processes and standardized procedures for managing PHI. This not only enhances security and privacy, but also can improve overall operational efficiency within organizations. 

</RegularText>

<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
<strong style={{ color: '#dddddd' }}>Use security as a differentiator</strong><br/>
Lastly, organizations should use security as a differentiator to enhance their reputation and give them a competitive edge. HIPAA compliance can demonstrate that your organization takes security, privacy, and compliance seriously, differentiating your business. A key step to proactively build trust with stakeholders is to undergo a security or privacy audit performed by an independent third party like BARR Advisory, which can be made easier with a compliance automation solution like <span style={{ color: '#dddddd', textDecoration: 'underline', cursor: 'pointer' }} onClick={() => window.location.href = '/'}>Delve</span>. These measures provide stakeholders with valuable information about the controls that are in place at an organization and any gaps that may increase the risk of unauthorized users gaining access to sensitive information.
</RegularText>












<SmallSubtitleText>Conclusion</SmallSubtitleText>
<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
Given the state of healthcare-related data breaches, HIPAA compliance is no longer merely a regulatory requirement—it’s a fundamental business practice. HIPAA should be used as a foundation, and organizations should build upon with security assessments like ISO 27001, SOC 2, or HITRUST.  By safeguarding patient privacy, mitigating risk, and streamlining business operations, organizations that comply with HIPAA can strengthen the foundation of security and privacy in the healthcare industry and beyond.
</RegularText>


<Box
    w="100%"
    borderWidth='1px'
    borderColor='rgba(255, 255, 255, 0.08)'
    h="auto"
    bg="rgba(255, 255, 255, 0.04)"
    mt="4vh"
    p="5"
    borderRadius="md"
    display="flex"
    flexDirection={{ base: "column", md: "row" }}
    alignItems="center"
>
    <Image
        src={SteveRyan}
        alt="Steve Ryan"
        borderRadius="full"
        boxSize={{ base: "150px", md: "200px" }}
        mr={{ base: "0", md: "4" }}
        mb={{ base: "4", md: "0" }}
    />
    <VStack align='left'>
        <SmallSubtitleText>Steve Ryan
</SmallSubtitleText>
        <RegularText sx={{ color: '#DDDDDD' }}>Head of Healthcare Services, BARR Advisory
</RegularText>
        <RegularText>
        Steve Ryan, head of healthcare services and attest manager at <a href="https://www.barradvisory.com/" style={{ textDecoration: 'underline'}}>BARR Advisory</a>, is responsible for planning and executing information technology audits and risk assessments for clients in the healthcare industry. He is experienced in various compliance areas including the Health Insurance Portability and Accountability Act (HIPAA), the HITECH ACT, and HITRUST, Meaningful Use risk assessments, NIST SP 800-53, ISO 27001, PCI, SOC 1 and 2 Reports, and other state privacy laws. Prior to joining BARR, Steve was a Senior Consultant in Wolf & Company’s IT Assurance practice. He holds a Bachelor of Science in information systems from Bentley University.
</RegularText>
    </VStack>
</Box>
</Box>
</VStack>
<Box mt='4rem' mb='3rem' height="1px" bgGradient="linear(to-l, transparent, #dddddd, transparent)" width="100%" />
<FooterPanel />
</Box>
);
};

export default LandingPage;