import React from 'react';
import { Box, VStack, useBreakpointValue, Image } from '@chakra-ui/react';
import NavBar from '../../components/general/navbar';
import FooterPanel from '../../components/panels/footerpanel';
import RegularText from '../../components/text/regulartext';
import SubtitleText from '../../components/text/subtitletext';
import BlogImage from '../../assets/blog/ChaseParsonsBlog.png';
import SmallSubtitleText from '../../components/text/smallsubtitletext';
import ChaseParsons from '../../assets/blog/ChaseParsons.png';
import SmallerSubtitleText from '../../components/text/smallersubtitletext';

const LandingPage = () => {
    const paddingX = useBreakpointValue({ base: 8, md: 60 }); // Adjusted paddingX to match other pages

    return (
        <Box bg="#101012" w="100%" h="auto">
            <Box position="fixed" top="0" left="0" width="100%" zIndex="1000">
                <NavBar />
            </Box>
            <VStack spacing={0} w="100%">
                <Box
                    position="relative"
                    textAlign="left"
                    color="white"
                    h="auto"
                    w="100%"
                    pt="20vh"
                    pr={paddingX}
                    pl={paddingX}
                    pb="5vh"
                >
                    <RegularText 
                        sx={{ mb: '2vh', mt: '1vh', cursor: 'pointer', display: 'flex', alignItems: 'center' }} 
                        onClick={() => window.location.href = '/blog'}
                    >
                        <span style={{ marginRight: '8px' }}>←</span>
                        Back to all blogs
                    </RegularText>
                    <Image src={BlogImage} alt="Blog" w="100%" borderRadius="xl" mb={10} />
                    <SubtitleText sx={{ lineHeight: '1'}}>The 2-minute guide to HIPAA compliance</SubtitleText>
                    <RegularText sx={{ color: '#dddddd', mt: '2vh' }}>February 28th, 2024</RegularText>

                    <RegularText sx={{ mb: '4vh', mt:'4vh' }}>
                        Almost all companies that process health data in the US need to become HIPAA compliant. Here’s the 2-minute breakdown of what HIPAA is, how to get compliant, and key tips and tricks.
                    </RegularText>

                    <SmallSubtitleText>What is HIPAA?</SmallSubtitleText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                        The Health Insurance Portability and Accountability Act (HIPAA) outlines how sensitive protected health information (PHI) should be handled in the US.
                    </RegularText>

                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                        <strong style={{ color: '#dddddd' }}>HIPAA applies to you if:</strong><br />
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>You deal with health information AND you work with another healthcare organization (i.e. hospital, clinic, digital health platform, etc. known as a “covered entity”)</li>
                        </ul>
                    </RegularText>

                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                    <strong style={{ color: '#dddddd' }}>Exceptions:</strong><br />
                        <ul style={{ paddingLeft: '20px' }}>
                            <li>You use de-identified health information (that doesn’t contain any names, emails, ID numbers, or other data outlined in HIPAA’s Safe Harbor method)</li>
                            <li>Your service is for consumer users ONLY and you don’t work with healthcare organizations (in which case getting compliant is highly encouraged but not required)</li>
                        </ul>
                    </RegularText>

                    <SmallSubtitleText>Consequences of not being HIPAA compliant:</SmallSubtitleText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                        If you get caught for not complying, you could face serious consequences:
                        <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                            <li>You could get fined (up to $1.5M/year)</li>
                            <li>You could be personally liable (criminally punishable)</li>
                            <li>You could lose your business license</li>
                        </ul>
                        HIPAA is enforced by the Office for Civil Rights (OCR) of the US Department of Health and Human Services (HHS). The Federal Trade Commission (FTC) also enforces good security practices and can prosecute companies that don’t have them in place.
                    </RegularText>

                    <SmallSubtitleText>Pros and cons of being HIPAA compliant</SmallSubtitleText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                        <strong style={{ color: '#dddddd' }}>Pros</strong>
                        <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                            <li>Ability to process PHI</li>
                            <li>It’s a selling point</li>
                            <li>Enhanced credibility + trust</li>
                            <li>Improved data security</li>
                        </ul>
                        <strong style={{ color: '#dddddd' }}>Cons</strong>
                        <ul style={{ paddingLeft: '20px'}}>
                            <li>Time + monetary cost</li>
                            <li>Additional security overhead</li>
                            <li>Restrictions on services that can be used</li>
                        </ul>
                    </RegularText>

                    <SmallSubtitleText>What’s required for HIPAA compliance?</SmallSubtitleText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                        To get HIPAA compliant, you must ensure that you are covered on both a technical and administrative level. On a high level, here are the requirements for compliance:
                    </RegularText>

                    <RegularText><strong style={{ color: '#dddddd' }}>Technical:</strong></RegularText>
                    <RegularText sx={{ mb: '4vh', mt: '1vh' }}>
                    <span style={{ color: '#dddddd' }}>Configure infrastructure that follows 2 core principles:</span>
                        <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                            <li>Least privilege access → encryption in transit and at rest, Identity and Access Management (IAM) role/access restriction, firewalls, Web Application Firewalls (WAFs), public and private subnets, Virtual Private Clouds (VPCs), etc.</li>
                            <li>High availability → load balancing, autoscaling, multi-availability zone/multi-region deployment, read replicas, blue green deployment, and rollbacks</li>
                        </ul>
                        <span style={{ color: '#dddddd' }}>Implement logging</span>
                        <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                            <li>System access logs → record who accesses the system with timestamps and outcome</li>
                            <li>User activity tracking → log specific actions performed by users, including interactions with sensitive data</li>
                            <li>Security and errors → log security incidents and errors in detail</li>
                        </ul>
                        <span style={{ color: '#dddddd' }}>Monitor your infrastructure</span>
                        <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                            <li>Automated alerts → have a system in place that warns you when an adversary is attempting a breach</li>
                            <li>Health monitoring → maintain a dashboard to keep track of systems and ensure availability</li>
                            <li>Review security → regularly verify data integrity, roll keys, and update with patches</li>
                        </ul>
                        </RegularText>

<RegularText><strong style={{ color: '#dddddd' }}>Administrative:</strong></RegularText>
<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
    <span style={{ color: '#dddddd' }}>Implement legal policies</span>
    <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
        <li>~20 required security and privacy policies</li>
        <li>Sign Business Associate Agreements (BAAs) with all 3rd party vendors (e.g. AWS, OpenAI, Supabase, Twilio, etc.)</li>
    </ul>
    <span style={{ color: '#dddddd' }}>Establish company protocols for questions like:</span>
    <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
        <li>Who can access what data?</li>
        <li>What happens if an employee leaves?</li>
        <li>Who responds if there is a data breach?</li>
    </ul>
    <span style={{ color: '#dddddd' }}>Continually manage and assess compliance</span>
    <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
        <li>Regularly conduct checks → risk assessments, vendor security reviews, and compliance trainings</li>
        <li>Stay on top of tasks → many small details like multi-factor authentication, using a GitHub Org, screen lock, IAM access controls, etc.</li>
    </ul>
</RegularText>

<SmallSubtitleText>What compliance solutions are available?</SmallSubtitleText>
<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
    <span style={{ marginBottom: '5vh' }}>Instead of doing things from scratch (very risky, expensive, & time-consuming), check out the solutions below:</span><br />
    </RegularText>
    <RegularText>
        <strong style={{ color: '#dddddd', marginTop: '5vh' }}>For technical tasks:</strong> Aptible, Porter, etc.
            <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                <li>Will help you deploy HIPAA-compliant infrastructure</li>
                <li>Total time: &lt; 5 days</li>
            </ul>
        <strong style={{ color: '#dddddd' }}>For administrative tasks:</strong> Vanta, Drata, Secureframe, Sprinto, etc.
            <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                <li>Will provide the non-technical tasks (i.e. legal policies, task checklist)</li>
                <li>Will run checks on your infrastructure setup but won’t fix it</li>
                <li>Total time: 3-4 weeks</li>
            </ul>
        <strong style={{ color: '#dddddd' }}>For BOTH technical & administrative tasks:</strong> Delve
            <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
                <li>Will deploy HIPAA-compliant infrastructure for you</li>
                <li>Will provide a monitoring/logging dashboard</li>
                <li>Will provide streamlined non-technical tasks (legal policies, task checklist, etc.)</li>
                <li>Total time: &lt; 2 days</li>
            </ul>
</RegularText>

<SmallSubtitleText>Key tips & tricks for HIPAA compliance</SmallSubtitleText>
<RegularText sx={{ mb: '4vh', mt: '1vh' }}>
    <ul style={{ paddingLeft: '20px' , marginBottom: '5vh'}}>
        <li><span style={{ color: '#dddddd' }}>Start early:</span> Getting HIPAA compliant becomes harder the later you do it (shift left approach). Building compliant from day 1 is much easier. Plus, you need to be compliant before you can process PHI anyway.</li>
        <li><span style={{ color: '#dddddd' }}>Use a compliance solution:</span> Unless you’re a lawyer or healthcare security expert, HIPAA compliance isn’t something you can easily DIY. Choose a pre-existing solution to ensure you don’t make mistakes.</li>
        <li><span style={{ color: '#dddddd' }}>Choose services carefully:</span> If you’re HIPAA compliant, you can only use HIPAA compliant services that offer BAAs (for example, you can’t use Heroku or OpenAI APIs until you sign a BAA).</li>
    </ul>
</RegularText>



<Box
    w="100%"
    borderWidth='1px'
    borderColor='rgba(255, 255, 255, 0.08)'
    h="auto"
    bg="rgba(255, 255, 255, 0.04)"
    mt="4vh"
    p="5"
    borderRadius="md"
    display="flex"
    flexDirection={{ base: "column", md: "row" }}
    alignItems="center"
>
    <Image
        src={ChaseParsons}
        alt="Chase Parsons"
        borderRadius="full"
        boxSize={{ base: "150px", md: "200px" }}
        mr={{ base: "0", md: "4" }}
        mb={{ base: "4", md: "0" }}
    />
    <VStack align='left'>
        <SmallSubtitleText>Dr. Chase Parsons</SmallSubtitleText>
        <RegularText sx={{ color: '#DDDDDD' }}>Chief Medical Information Officer, Boston Children's Hospital</RegularText>
        <RegularText>
            Dr. Parsons is the Chief Medical Information Officer (CMIO) at Boston Children’s Hospital. He attended medical school at Philadelphia College of Osteopathic Medicine and completed his residency in Internal Medicine and Pediatrics at Geisinger Medical Center in Danville, Pennsylvania. He then trained in a Clinical Informatics fellowship at Boston Children’s Hospital and earned his Masters in Biomedical Informatics from Harvard Medical School.
        </RegularText>
    </VStack>
</Box>
</Box>
</VStack>
<Box mt='4rem' mb='3rem' height="1px" bgGradient="linear(to-l, transparent, #dddddd, transparent)" width="100%" />
<FooterPanel />
</Box>
);
};

export default LandingPage;