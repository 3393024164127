import React from 'react';
import { Text } from '@chakra-ui/react';

const RegularText = ({ children, sx, onClick }) => {
  return (
    <Text fontSize={['sm', 'sm', 'lg', '2xl']} color='#848484' sx={sx} onClick={onClick}>
      {children}
    </Text>
  );
};

export default RegularText;