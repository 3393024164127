import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { ChakraProvider } from '@chakra-ui/react';
import Routes from './routes';
import reportWebVitals from './reportWebVitals';

import posthog from 'posthog-js'

posthog.init('phc_859u69gWOZqpJReVCyXOt9vjCPO6QEEtS3Z3SjbQSA',
    {
        api_host: 'https://us.i.posthog.com',

    }
)

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <ChakraProvider>
      <Routes />
    </ChakraProvider>
  </React.StrictMode>
);

reportWebVitals();