import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import App from './App';

import LandingPage from './pages/landingpage.js';
import BlogPage from './pages/blogpage.js';
import BenefitsofHIPAA from './pages/blogs/benefitsofhipaa.js';
import ExpertInterview from './pages/blogs/expertinterview.js';
import TwoMinuteGuide from './pages/blogs/twominuteguide.js';
import WhoDoesHIPAAApplyTo from './pages/blogs/whodoeshipaapplyto.js';

const AppRoutes = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/the-benefits-of-hipaa-compliance" element={<BenefitsofHIPAA />} />
        <Route path="/blog/expert-interview-hipaa-compliance-for-saas-companies" element={<ExpertInterview />} />
        <Route path="/blog/the-two-minute-guide-to-hipaa-compliance" element={<TwoMinuteGuide />} />
        <Route path="/blog/who-does-hipaa-apply-to" element={<WhoDoesHIPAAApplyTo />} />
      </Routes>
    </Router>
  );
};

export default AppRoutes;