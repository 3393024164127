import React from 'react';
import { Box, useBreakpointValue } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import BlogCover from '../../assets/blogcover.png';
import SubtitleText from '../text/subtitletext';
import RegularText from '../text/regulartext';

const TitlePanel = () => {
  const paddingX = useBreakpointValue({ base: 4, md: 20 });
  const lineHeight = useBreakpointValue({ base: '1.2', md: '1.0' }); // Define lineHeight here

  const MotionBox = motion(Box);

  return (
    <MotionBox
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1.0 }}
      position="relative"
      textAlign="center"
      color="white"
      h='auto'
      w='100%'
      pt='20vh'
      px={paddingX}
      backgroundImage={`url(${BlogCover})`}
      backgroundSize="cover"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      pb='5vh'
    >
      <MotionBox
        initial={{ opacity: 0, y: 40 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.9 }}
      >
        <SubtitleText sx={{ mb: { base: '0rem', md: '0rem' }, lineHeight: lineHeight, overflowY: 'visible' }}>
          Blog
        </SubtitleText>
      </MotionBox>


      <MotionBox
        initial={{ opacity: 0, y: 50 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 1.0 }}
      >
        <RegularText sx={{ mt: { base: '0.5rem', md: '1rem' } }}>
          Understand everything you need to know about compliance, learn from experts, and hear founder-to-founder insights.
        </RegularText>
      </MotionBox>
    </MotionBox>
  );
};

export default TitlePanel;